import { useQuery } from '@apollo/client';
import { Tabs } from 'antd';
import { nth, parseInt } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ForwardArrow, Globe } from '../../../../assets/svg';
import { ROUTES } from '../../../../common/constants';
import Portal from '../../../../components/Portal';
import history from '../../../../historyData';
import useRouter from '../../../../hooks/useRouter';
import { GET_ORGANIZATIONS } from '../../graphql/Queries';
import Details from './pages/Details';
import Users from './pages/Users';

const OrganizationDetails = () => {
  const {
    navigate,
    location,
    params: { id },
  } = useRouter();
  const [activeKey, setActiveKey] = useState('details');
  const navPagination = location?.state?.navPagination;
  const navFilter = location?.state?.navFilter;

  const { data, refetch: fetchOrganizationData } = useQuery(GET_ORGANIZATIONS, {
    skip: !id,
    variables: {
      filter: { tenantId: parseInt(id) },
      sortBy: { field: 'isDisabled', order: 'ASC' },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const key = location.pathname.includes('details') ? 'details' : 'users';
    setActiveKey(key);

    const unlisten = history.listen((props) => {
      if (props.action === 'POP') {
        navigate(props.location.pathname, {
          state: { navPagination, navFilter },
        });
      }
    });
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, []);

  const fetchData = nth(data?.tenantList?.data, 0);

  const items = [
    {
      key: 'details',
      label: 'Details',
      children: (
        <div className="ant-tabs-tabpane ant-tabs-tabpane-active details-tab">
          <div className="organization-details">
            <Details
              data={data}
              fetchOrganizationData={fetchOrganizationData}
              pageFilter={{ navPagination, navFilter }}
            />
          </div>
        </div>
      ),
    },
    {
      key: 'users',
      label: 'Users',
      children: (
        <div className="ant-tabs-tabpane ant-tabs-tabpane-active">
          <div className="users">
            <Users id={id} data={data} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="details">
        <Portal portalId="header-left-content">
          <h1>
            <div className="d-flex">
              <Globe
                onClick={() =>
                  navigate(ROUTES.ORGANIZATION, {
                    state: {
                      navPagination,
                      navFilter,
                    },
                  })
                }
                className="pointer"
              />
              <ForwardArrow />
            </div>
            <span className="break-all-words">
              {fetchData?.organizationName}
            </span>
          </h1>
        </Portal>
        <div className="details-content">
          <Tabs
            defaultActiveKey="details"
            items={items}
            onChange={(key) => {
              setActiveKey(key);
              navigate(`${ROUTES.ORGANIZATION}/${id}/${key}`, {
                state: {
                  navPagination,
                  navFilter,
                },
              });
            }}
            activeKey={activeKey}
          />
        </div>
      </div >
    </>
  );
};

export default OrganizationDetails;
