import { useLazyQuery } from '@apollo/client';
import { Button, Col, Descriptions, Image, Popover, Row } from 'antd';
import clsx from 'clsx';
import { keys, map, toUpper } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { InfoIconDark } from '../../assets/svg';
import {
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  IMAGE_FILTERS,
  INS_STATUS_KEYS,
} from '../../common/constants';
import { titleCase } from '../../common/utils';
import CollapsibleFilterWrapper from '../../components/CollapsibleFilterWrapper';
import CommonDropdown from '../../components/CommonDropdown';
import CommonSelect from '../../components/CommonSelect';
import CustomRangePicker from '../../components/CustomRangePicker';
import InfiniteScrollHandler from '../../components/InfiniteScrollHandler';
import Portal from '../../components/Portal';
import useRouter from '../../hooks/useRouter';
import {
  ADMIN_ASSET_LIST,
  ASSET_EQC_TYPE_DROPDOWN_LIST,
  ASSET_EQC_TYPE_STAGE_DROPDOWN_LIST,
  ASSET_EQC_TYPE_STAGE_ITEM_DROPDOWN_LIST,
  ASSET_PROJECT_DROPDOWN_LIST,
  TENANT_DROPDOWN_LIST,
} from './graphql/Queries';

const Gallery = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [selectedDates, setSelectedDates] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [featureType, setFeatureType] = useState();
  const { location } = useRouter();

  const instructionFilter = location?.state?.instructionFilter;
  const instructionPagination = location?.state?.instructionPagination;
  const initialInstructionLogFilter = {
    skip: 0,
    limit: 30,
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const [paginationProp, setPaginationProp] = useState(
    instructionPagination || initialPaginationValue,
  );

  const [instructionLogFilter, setInstructionLogFilter] = useState(
    instructionFilter || initialInstructionLogFilter,
  );
  const [projectId, setProjectId] = useState(instructionFilter?.projectId);
  const [tenantId, setTenantId] = useState(instructionFilter?.tenantId);
  const [selectedProjectRecord, setSelectedProjectRecord] = useState();
  const [status, setStatus] = useState(instructionFilter?.status);
  const [imageFilter, setImageFilter] = useState(
    instructionFilter?.imageFilter || IMAGE_FILTERS?.ALL_IMAGES,
  );
  const [eqcType, setEqcType] = useState(instructionFilter?.eqcType);
  const [eqcStage, setEqcStage] = useState(instructionFilter?.eqcStage);
  const [eqcStageItem, setEqcStageItem] = useState(
    instructionFilter?.eqcStageItem,
  );
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setProjectId(instructionFilter?.projectId);
  }, [instructionFilter?.projectId]);

  useEffect(() => {
    setStatus(instructionFilter?.status);
  }, [instructionFilter?.status]);

  const [fetchInstructionLogs, { loading }] = useLazyQuery(ADMIN_ASSET_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.adminAssetList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.adminAssetList?.total,
      };
      setPaginationProp(pagination);
      setHasMore(!!data?.length);
      if (scrollFlag) {
        const datalist = [...activityData, ...data];
        setActivityData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setActivityData(datalist);
      }
    },
    onError() { },
  });

  useEffect(() => {
    const newFilter = {
      ...instructionLogFilter,
      projectId,
      status,
      projectEqcTypeId: eqcType,
      projectEqcTypeStageId: eqcStage,
      projectEqcTypeStageItemId: eqcStageItem,
      featureType,
      tenantId,
      skip: 0,
      fromDate: selectedDates?.[0] && moment(selectedDates?.[0]).startOf('day'),
      endDate: selectedDates?.[1] && moment(selectedDates?.[1]).endOf('day'),
    };
    setPaginationProp({ ...paginationProp, current: 1 });
    setInstructionLogFilter(newFilter);
    fetchInstructionLogs({ variables: { filter: { ...newFilter } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    status,
    tenantId,
    eqcType,
    eqcStage,
    featureType,
    eqcStageItem,
    projectId,
    selectedDates,
  ]);

  const instructionLogsData = async (value) => {
    fetchInstructionLogs({
      variables: {
        filter: {
          ...instructionLogFilter,
          skip: 0,
          limit: DEFAULT_PAGE_SIZE,
          search: value,
        },
      },
    });
    setInstructionLogFilter({
      ...instructionLogFilter,
      skip: 0,
      limit: DEFAULT_PAGE_SIZE,
      search: value,
    });
  };

  const handleRefetch = () => {
    fetchInstructionLogs({
      variables: {
        filter: {
          ...instructionLogFilter,
          skip: activityData?.length,
          limit: DEFAULT_PAGE_SIZE,
        },
      },
    });
  };
  const headerFilters = () => {
    return (
      <div>
        <Row
          justify={isDesktopViewport ? 'end' : 'start'}
          className="d-flex filter-search align-center mb-10"
          wrap
          gutter={isDesktopViewport ? [10, 10] : [0, 10]}
        >
          <Col span={isDesktopViewport ? null : 24}>
            <CustomRangePicker
              className={clsx(!isDesktopViewport && 'width-percent-100')}
              setDateSelected={setSelectedDates}
            />
          </Col>
          <Col span={isDesktopViewport ? null : 24}>
            <CommonSelect
              name="imageFilter"
              className={`instruction-selector ${isDesktopViewport ? 'width-200' : 'width-percent-100'
                } `}
              value={imageFilter}
              onChange={(text) => {
                if (text !== IMAGE_FILTERS?.ALL_IMAGES) {
                  setFeatureType(toUpper(text));
                } else {
                  setFeatureType();
                }
                setImageFilter(text);
                setTenantId();
                setProjectId();
                setEqcType();
                setEqcStage();
                setEqcStageItem();
              }}
              options={[
                ...keys(IMAGE_FILTERS).map((text) => (
                  {
                    label: `${titleCase(IMAGE_FILTERS[text])}s`,
                    value: IMAGE_FILTERS[text],
                  }
                )),
              ]}
            />
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <div>
      {!isDesktopViewport && headerFilters()}
      <div className="d-flex justify-between width-percent-100 mb-10">
        {isDesktopViewport && (
          <div className="image-count">{`${paginationProp?.total} Images`}</div>
        )}
        {isDesktopViewport && (
          <Portal portalId="header-right-content">{headerFilters()}</Portal>
        )}

        <div className={isDesktopViewport ? '' : 'width-percent-100'}>
          <CollapsibleFilterWrapper
            searchProps={{
              className: 'search-component',
              getData: instructionLogsData,
              disabled: true,
            }}
            className={clsx(
              imageFilter === IMAGE_FILTERS.ALL_IMAGES && 'd-none',
            )}
          >
            <Row
              justify={isDesktopViewport ? 'end' : 'start'}
              className="d-flex filter-search align-center"
              wrap
              gutter={isDesktopViewport ? [10, 10] : [0, 10]}
            >
              {imageFilter !== IMAGE_FILTERS.ALL_IMAGES && (
                <>
                  <Col span={isDesktopViewport ? null : 12}>
                    <CommonDropdown
                      allowClear
                      placeholder="Tenant"
                      className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
                        } `}
                      value={tenantId}
                      onChange={(id) => {
                        setTenantId(id);
                        setProjectId();
                        setEqcType();
                        setEqcStage();
                        setEqcStageItem();
                      }}
                      query={TENANT_DROPDOWN_LIST}
                      fetchPolicy="network-only"
                      responsePath="tenantDropdownList.data"
                      valuePath="id"
                      labelPath="organizationName"
                      optionKey="tenant"
                      showSearch
                      optionFilterProp="children"
                      customOptions={selectedProjectRecord}
                      popupMatchSelectWidth={false}
                    />
                  </Col>
                  <Col span={isDesktopViewport ? null : 12}>
                    <CommonDropdown
                      allowClear
                      placeholder="Project"
                      className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
                        } `}
                      value={projectId}
                      onChange={(id, record) => {
                        setProjectId(id);
                        setSelectedProjectRecord(record);
                        setEqcType();
                        setEqcStage();
                        setEqcStageItem();
                      }}
                      query={ASSET_PROJECT_DROPDOWN_LIST}
                      fetchPolicy="network-only"
                      responsePath="assetProjectDropdownList.data"
                      valuePath="id"
                      labelPath="name"
                      optionKey="project"
                      showSearch
                      optionFilterProp="children"
                      customOptions={selectedProjectRecord}
                      popupMatchSelectWidth={false}
                      variables={{
                        filter: { tenantId },
                      }}
                      useEffectDeps={[tenantId]}
                      conditionToCheckBeforeQuery={!!tenantId}
                      projectId={tenantId}
                      disabled={!tenantId}
                    />
                  </Col>
                </>
              )}
              {imageFilter === IMAGE_FILTERS?.INSPECTIONS && (
                <>
                  <Col span={isDesktopViewport ? null : 12}>
                    <CommonDropdown
                      allowClear
                      placeholder="Checklist"
                      className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
                        } `}
                      value={eqcType}
                      onChange={(type) => {
                        setEqcType(type);
                        setEqcStage();
                        setEqcStageItem();
                      }}
                      variables={{
                        filter: { projectId },
                      }}
                      query={ASSET_EQC_TYPE_DROPDOWN_LIST}
                      projectId={projectId}
                      fetchPolicy="network-only"
                      responsePath="assetProjectEqcTypeDropdownList.data"
                      valuePath="id"
                      labelPath="name"
                      optionKey="eqc-type"
                      showSearch
                      optionFilterProp="children"
                      popupMatchSelectWidth={false}
                      useEffectDeps={[projectId]}
                      conditionToCheckBeforeQuery={!!projectId}
                      disabled={!projectId}
                    />
                  </Col>
                  <Col span={isDesktopViewport ? null : 12}>
                    <CommonDropdown
                      allowClear
                      placeholder="Stage"
                      className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
                        } `}
                      value={eqcStage}
                      onChange={(stage) => {
                        setEqcStage(stage);
                        setEqcStageItem();
                      }}
                      query={ASSET_EQC_TYPE_STAGE_DROPDOWN_LIST}
                      fetchPolicy="network-only"
                      responsePath="assetProjectEqcTypeStageDropdownList.data"
                      valuePath="id"
                      labelPath="name"
                      optionKey="eqc-type"
                      showSearch
                      optionFilterProp="children"
                      popupMatchSelectWidth={false}
                      variables={{
                        filter: { projectEqcTypeId: eqcType },
                      }}
                      useEffectDeps={[eqcType]}
                      conditionToCheckBeforeQuery={!!eqcType}
                      projectId={eqcType}
                      disabled={!eqcType}
                    />
                  </Col>
                  <Col span={isDesktopViewport ? null : 12}>
                    <CommonDropdown
                      allowClear
                      placeholder="Stage Item"
                      className={`instruction-selector dropdown-width-auto ${isDesktopViewport ? 'width-200' : 'width-percent-100'
                        } `}
                      value={eqcStageItem}
                      onChange={(type) => {
                        setEqcStageItem(type);
                      }}
                      query={ASSET_EQC_TYPE_STAGE_ITEM_DROPDOWN_LIST}
                      fetchPolicy="network-only"
                      responsePath="assetProjectEqcTypeStageItemDropdownList.data"
                      valuePath="id"
                      labelPath="title"
                      optionKey="eqc-type"
                      showSearch
                      optionFilterProp="children"
                      popupMatchSelectWidth={false}
                      variables={{
                        filter: { projectEqcTypeStageId: Number(eqcStage) },
                      }}
                      useEffectDeps={[eqcStage]}
                      conditionToCheckBeforeQuery={!!eqcStage}
                      projectId={eqcStage}
                      disabled={!eqcStage}
                    />
                  </Col>
                </>
              )}
              {imageFilter === IMAGE_FILTERS?.INSTRUCTIONS && (
                <Col span={isDesktopViewport ? null : 12}>
                  <CommonSelect
                    allowClear
                    name="status"
                    placeholder="Issue Type"
                    className={`instruction-selector ${isDesktopViewport ? 'width-200' : 'width-percent-100'
                      } `}
                    value={status}
                    onChange={(eqcStatus) => {
                      setStatus(eqcStatus);
                    }}
                    options={[
                      ...keys(INS_STATUS_KEYS).map((statusText) => (
                        {
                          label: titleCase(statusText),
                          value: statusText,
                        }
                      )),
                    ]}
                  />
                </Col>
              )}
            </Row>
          </CollapsibleFilterWrapper>
        </div>
      </div>
      <InfiniteScrollHandler
        scrollFlag={scrollFlag}
        loading={loading}
        refetchData={handleRefetch}
        setScrollFlag={setScrollFlag}
        hasMore={hasMore}
        wrapperClassName={clsx(
          'instruction-scroll-wrapper image-list',
          !activityData?.length > 0 && !loading && 'd-flex justify-center',
        )}
        dataLength={activityData?.length}
      >
        <Image.PreviewGroup
          preview={{
            visible: isVisible,
            onVisibleChange: (visible) => {
              setIsVisible(visible);
            },
          }}
        >
          {map(activityData, (data) => {
            return (
              <span className="gallery-image" key={data?.id}>
                <Image
                  src={data?.thumbnailUrl}
                  alt="logo"
                  height={isDesktopViewport ? '154px' : '120px'}
                  loading="lazy"
                  preview={{ src: data?.imageUrl }}
                />
                <Popover
                  placement="right"
                  getPopupContainer={() =>
                    // eslint-disable-next-line no-undef
                    document.querySelector('.instruction-scroll-wrapper')
                  }
                  overlayClassName="gallery-image-popover"
                  trigger={[!isDesktopViewport ? 'click' : 'hover']}
                  content={
                    <div className="p-16">
                      <h2 className="mb-10 mt-0">Details</h2>
                      <Descriptions
                        layout="horizontal"
                        colon={false}
                        column={1}
                      >
                        <Descriptions.Item label="Tenant">
                          <div>
                            {data?.eqc?.tenant?.organizationName ||
                              data?.instruction?.tenant?.organizationName ||
                              '-'}
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Project">
                          <div>
                            {data?.eqc?.project?.name ||
                              data?.instruction?.project?.name ||
                              '-'}
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={data?.eqcId ? 'EQC' : 'Instruction'}
                        >
                          {data?.eqc?.eqcName || data?.instruction?.name || '-'}
                        </Descriptions.Item>
                        {data?.eqcId && (
                          <Descriptions.Item label="Stage">
                            {data?.eqcStage?.name || '-'}
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label="Image type">
                          {titleCase(data?.assetName) || '-'}
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  }
                >
                  <Button
                    shape="default"
                    className="info-button"
                    icon={<InfoIconDark height="16px" width="16px" />}
                    onClick={(e) => {
                      if (!isDesktopViewport) e?.stopPropagation();
                    }}
                  />
                </Popover>
              </span>
            );
          })}
        </Image.PreviewGroup>
      </InfiniteScrollHandler>
    </div>
  );
};

export default Gallery;
