import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Alert } from 'antd';
import React, { useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import './assets/vendors/style';
import { ROUTES } from './common/constants';
import { useOnlineStatus } from './common/useNetworkDetect';
import HistoryRouter from './components/HistoryRouter';
import LoaderComponent from './components/LoaderComponent';
import history from './historyData';
import ChangePassword from './modules/auth/ChangePassword';
import ForgotPassword from './modules/auth/ForgotPassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import Maintenance from './modules/auth/Maintenance';
import RefreshToken from './modules/auth/RefreshToken';
import ResetPassword from './modules/auth/ResetPassword';
import GalleryListing from './modules/gallery/GalleryListing';
import AddOrganization from './modules/organizations/AddOrganization';
import EditOrganization from './modules/organizations/EditOrganization';
import Organizations from './modules/organizations/Organizations';
import OrganizationDetails from './modules/organizations/components/organizationDetails/OrganizationDetails';
import Users from './modules/users/Users';
import { GET_PROFILE } from './modules/users/graphql/Queries';

const MyFallbackComponent = ({ error, componentStack }) => (
  <div>
    <p>
      <strong>Oops! An error occured!</strong>
    </p>
    <p>Below is the details…</p>
    <p>
      <strong>Error:</strong> {error.toString()}
    </p>
    <p>
      <strong>Stacktrace:</strong> {componentStack}
    </p>
  </div>
);

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PublicRoute />,
      children: [
        { path: ROUTES?.LOGIN, element: <Login /> },
        { path: ROUTES?.RESET, element: <ResetPassword /> },
        { path: ROUTES?.FORGOT, element: <ForgotPassword /> },
      ],
    },
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        { path: ROUTES?.LOGOUT, element: <Logout /> },
        { path: ROUTES?.AUTHENTICATION, element: <RefreshToken /> },
      ],
    },
  ];

  const ORGANIZATION_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: ROUTES?.ORGANIZATION, element: <Organizations /> },
            {
              path: `${ROUTES?.ORGANIZATION}${ROUTES?.EDIT}`,
              element: <EditOrganization />,
            },
            {
              path: `${ROUTES?.ORGANIZATION}${ROUTES?.DETAILS}`,
              element: <OrganizationDetails />,
            },
            {
              path: `${ROUTES?.ORGANIZATION}${ROUTES?.ADD}`,
              element: <AddOrganization />,
            },

            {
              path: `${ROUTES?.ORGANIZATION}${ROUTES?.USER}`,
              element: <OrganizationDetails />,
            },
          ],
        },
      ],
    },
  ];

  const USER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES.USERS,
              element: <Users />,
            },
            { path: ROUTES?.CHANGE, element: <ChangePassword /> },
          ],
        },
      ],
    },
  ];

  const GALLERY_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES.GALLERY,
              element: <GalleryListing />,
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULES,
    ...ORGANIZATION_MODULES,
    ...USER_MODULES,
    ...GALLERY_MODULES,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const { initializeAuth, getToken } = useContext(AppContext);
  const idToken = getToken();
  const isOnline = useOnlineStatus();

  const { loading } = useQuery(GET_PROFILE, {
    // eslint-disable-next-line no-undef
    skip: !idToken || window?.location?.pathname === ROUTES.LOGOUT,
    onCompleted: () => {
      initializeAuth();
    },
  });

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;

  return (
    <>
      {!isOnline && (
        <div className="alert-div">
          <Alert
            message="Please check your network connection and try again."
            type="error"
            banner
          />
        </div>
      )}
      <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
        {maintenance === 'true' ? (
          <Maintenance />
        ) : (
          <HistoryRouter history={history}>
            <RoutesCollection />
          </HistoryRouter>
        )}
      </Sentry.ErrorBoundary>
    </>
  );
};
export default RoutesWrapper;
