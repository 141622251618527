import { Dropdown, Layout, Menu, Popover, Tag } from 'antd';
import * as eva from 'eva-icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CloseIcon, InfoIconDark, MenuBar } from '../../../assets/svg';
import { LoginLogo } from '../../../assets/svg/login';
import { Account } from '../../../assets/svg/sider';
import { MODULES, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';

const { Header } = Layout;

const MobileHeader = () => {
  const [visible, setVisible] = useState(false);
  const {
    location: { pathname },
  } = useRouter();

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menus = [
    {
      key: ROUTES.ORGANIZATION,
      label: (
        <Link to={ROUTES.ORGANIZATION} className="d-flex align-center">
          {MODULES.ORGANIZATIONS}
        </Link>
      ),
    },
    {
      key: ROUTES.USERS,
      label: (
        <Link to={ROUTES.USERS} className="d-flex align-center">
          {MODULES.USERS}
        </Link>
      ),
    },
    {
      key: ROUTES.GALLERY,
      label: (
        <div className="d-flex align-center">
          <Link
            to={ROUTES.GALLERY}
            className="d-flex align-center gallery-heading"
          >
            {MODULES.GALLERY}
            <Tag className="tag-beta ml-5" color="#202A3F">
              BETA
            </Tag>
          </Link>
          <Popover
            content={
              <>
                This is beta feature and may have bug and errors. This feature
                can be stopped without any prior notice.
              </>
            }
            placement="bottom"
            overlayClassName="gallery-info"
          >
            <InfoIconDark
              height="18px"
              width="18px"
              onClick={(e) => {
                e?.stopPropagation();
              }}
            />
          </Popover>
        </div>
      ),
    },
  ];

  const profileItem = [
    {
      label: 'Account',
      key: 'SubMenu',
      icon: <Account />,
      children: [
        {
          key: ROUTES.CHANGE,
          label: (
            <Link to={ROUTES.CHANGE} className="d-flex align-center">
              Change Password
            </Link>
          ),
        },
        {
          label: (
            <Link to={ROUTES.LOGOUT} className="d-flex align-center">
              Logout
            </Link>
          ),
          key: ROUTES.LOGOUT,
        },
      ],
    },
  ];

  return (
    <Header>
      <div className="d-flex mobile-header justify-between align-center width-percent-100 ">
        <div className="d-flex align-center">
          <Dropdown
            menu={{
              items: menus,
              selectedKeys: [`/${pathname?.split('/')[1]}`],
            }}
            trigger={['click']}
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('#nav-bar-dropdown')
            }
            onOpenChange={(value) => {
              setVisible(value);
            }}
            open={visible}
          >
            <div className="mobile-menu-trigger d-flex">
              {visible ? (
                <CloseIcon height={34} width={34} />
              ) : (
                <MenuBar height={34} width={34} />
              )}
            </div>
          </Dropdown>
          <div className="header-logo">
            <Link to={ROUTES.MAIN} className="gx-site-logo mr-25">
              <LoginLogo />
            </Link>
          </div>
        </div>
      </div>
      <div id="nav-bar-dropdown" />
      <div className="header-right">
        <Menu
          selectedKeys={[`/${pathname?.split('/')[1]}`]}
          mode="horizontal"
          items={profileItem}
        />
      </div>
    </Header>
  );
};
export default MobileHeader;
