import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import { REFRESH_TOKEN } from './graphql/Queries';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken, dispatch } = useContext(AppContext);
  const { navigate } = useRouter();
  const refreshToken = getRefreshToken();
  function successCallback(accessToken, authRefreshToken) {
    initializeAuth(accessToken, authRefreshToken);
  }

  const { loading } = useQuery(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
    variables: {
      data: { refreshToken },
    },
    onCompleted(response) {
      const accessToken = get(response, 'refreshToken.token');
      const authRefreshToken = get(response, 'refreshToken.refreshToken');
      successCallback(accessToken, authRefreshToken);
      navigate(ROUTES.ORGANIZATION);
    },
    onError() {
      dispatch({ type: 'LOGOUT' });
      // eslint-disable-next-line no-undef
      window.location = '/login';
    },
  });

  if (loading) return <LoaderComponent />;
  return null;
};

export default RefreshToken;
