import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { Email, LoginLogo, Password } from '../../assets/svg/login';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const Login = () => {
  const { initializeAuth } = useContext(AppContext);
  const { navigate } = useRouter();

  function successCallback(accessToken, refreshToken) {
    initializeAuth(accessToken, refreshToken);
    navigate(ROUTES.RESET);
  }

  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {},
    onCompleted(response) {
      const accessToken = get(response, 'login.token');
      const refreshToken = get(response, 'login.refreshToken');
      if (successCallback) {
        successCallback(accessToken, refreshToken);
      }
    },
  });

  const onFinish = async (values) => {
    try {
      await loginMutate({
        variables: { data: { ...values, adminAccess: true } },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error from login => ', error);
    }
  };

  return (
    <div className="gx-login-container">
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <Spin spinning={loginLoading} wrapperClassName="gx-login-content">
        <div className="card-body">
          <h1>Login</h1>
          <p>Sign in to your admin account</p>
          <Form
            layout="vertical"
            name="Login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
          >
            <Form.Item
              name="email"
              rules={[required, email]}
              label="Email Address"
            >
              <Input
                addonBefore={<Email />}
                placeholder="Enter Email"
                maxLength={254}
              />
            </Form.Item>

            <Form.Item name="password" rules={[required]} label="Password">
              <Input.Password
                addonBefore={<Password />}
                placeholder="At least 8 characters long"
                maxLength={100}
              />
            </Form.Item>
            <Row className="login-row">
              <Col>
                <Link to={ROUTES.FORGOT}>Forgot password ?</Link>
              </Col>
              <Col>
                <Form.Item className="login-button">
                  <Button type="primary" htmlType="submit" shape="round">
                    Login
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default Login;
