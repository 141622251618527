import React from 'react';
import Portal from '../../components/Portal';
import UserTable from './components/UserTable';

const Users = () => (
  <div>
    <Portal portalId="header-left-content">
      <h1 className="portal-header">Users</h1>
    </Portal>
    <div className="users-content">
      <div className="users-table">
        <UserTable />
      </div>
    </div>
  </div>
);

export default Users;
